import Vue from "vue";
import VueRouter from 'vue-router'
//1创建路由
import Home from '../views/Home.vue'
import User from '../views/User.vue'
import Main from '../views/Main.vue'
import Mall from '../views/Mall.vue'
import PageOne from '../views/PageOne.vue'
import PageTwo from '../views/PageTwo.vue'
import Login from '../views/Login.vue'
import Menu from '../views/Menu.vue'
import Dept from "@/views/Dept.vue";
import Carousel from "../views/Carousel.vue"
import ProductArea from "@/views/ProductArea.vue";
import ProductRecommend from "@/views/ProductRecommend.vue";
import ProjectComming from "@/views/ProjectComming.vue";
import ProjectRegister from "@/views/ProjectRegister.vue";
import InnovativeProduct from "@/views/InnovativeProduct.vue";
import RevisingProject from "@/views/RevisingProject.vue";
import Column from '../views/Column.vue'
import WxQrcode from "@/views/WxQrcode.vue";
import Cookie from 'js-cookie'
// import Perms from "../views/Perms.vue";

Vue.use(VueRouter)


//2 将路由与组件做映射
const routes = [

    //主路由、
    {
        path: '/',
        component: Main,
        redirect: '/home',  //当为'/'重定向到home去
        name:"main",
        children: [
            // { path: '/home', name: 'home', component: Home },
            // { path: '/user', name: 'user', component: User },
            // { path: '/mall', name: 'mall', component: Mall },
            // { path: '/page1', name: 'page1', component: PageOne },
            // { path: '/page2', name: 'page2', component: PageTwo },
        ]
    },{
        path:'/login',
        component:Login,
        name: 'login'
        
    }


]


//3创建router实例

const router = new VueRouter({
    routes

})


router.beforeEach((to,from ,next)=>{
    const  token  =Cookie.get("token")
    console.log(token,"token")
    //token 不存在
    if(!token && to.name !=='login'){
        next({name:"login"})
    }else if(token && to.name ==='login'){
        //token存在，且在登录界面
        next({name:"home"})
    }else{
        next()
    }
  })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}





export default router