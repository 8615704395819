import http from '../utils/request'


// 获取轮播图详情，
export const getQrCodeDataInfo = (params) => {
    return http.get("/wx-qrcode/getQrCodeData",params)
}


export const uploadFile = (data) => {
    const formData =new FormData();
    formData.append("file",data)
    return http.post("/wx-qrcode/uploadFile",formData)
}


export const deletFile = (data) => {
    const formData =new FormData();
    formData.append("filepath",data)
    return http.post("/wx-qrcode/deletFile",formData)
}

//轮播图保存
export const saveQrcodeInfo = (data) => {
    
    return http.post("/wx-qrcode/save", data)
}


// 删除
export const batchDelete = (params) => {
    return http.get("/wx-qrcode/delete", params)
}

