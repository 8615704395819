<template>
  <div>
    <div class="manage-header">
      <el-button type="primary" size="small" @click="() => newWxQrcode()">
        + 新增微信联系人</el-button
      >
    </div>
    <el-table max-height="680" :data="tableData" style="width: 100%">
      <el-table-column min-width="15%" prop="phonenumber" label="联系人电话">
      </el-table-column>
      <el-table-column min-width="15%" prop="qrcode" label="二维码">
        <template slot-scope="scope">
          <img :src="scope.row.qrcode" min-width="70" height="70" />
        </template>
      </el-table-column>

      <el-table-column min-width="20%" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
        

          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[15, 30, 50, 100, 200]"
        :page-size="15"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <!-- 表单信息 -->
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item v-show="showflag" label="id" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item>

      

        <el-form-item label="手机号" prop="phonenumber">
          <el-input v-model="form.phonenumber" placeholder=""></el-input>
        </el-form-item>
     
       

        <el-form-item label="微信二维码" prop="qrcode">
          <el-upload
            class="avatar-uploader"
            action="#"
            :http-request="uploadQrcodeinfo"
            :show-file-list="false"
            accept="image/*"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="form.qrcode" :src="form.qrcode" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <!-- <el-button @click="clear">清 除</el-button> -->
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
          
          <script>
import {
  getQrCodeDataInfo,
  uploadFile,
  deletFile,
  saveQrcodeInfo,
  
  batchDelete,
} from "@/api/qrcode";
import qs from "qs";
export default {
  methods: {
    newWxQrcode() {
      this.title = "新增微信联系人";
      this.action = "new";
      this.clear();
      this.dialogVisible = true;
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },

    beforeUpload(file) {
      // 截取上传文件的后缀名
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      console.log(fileType);
      // 判断文件名的类型，允许多种就判断多个
      if (fileType !== "jpg" && fileType !== "jpeg" && fileType !== "png") {
        this.$message.warning(`请上传jpg、jpge或png的图片！`);
        this.fileList = [];
        return false;
      }
    },

    // 处理预览操作
    handlePreview(file) {
      console.log(this.fileList[0]);
      console.log(file, "handlePreview");
    },

  
    //上传二维码信息
    uploadQrcodeinfo(file) {
      uploadFile(file.file).then((res) => {
        if (res.data.code === 200) {
          this.form.qrcode = res.data.data.filePath;
        }
      });
    },

      

    // 处理超出图片个数操作
    handleExceed(files, fileList) {
      this.$message.warning(
        `只能上传一个文件，若需要上传，请先删除之前上传的文件，再上传新的图片`
      );
    },

    quillEditorFocus() {},

    // 移除之前的操作
    beforeRemove(file, fileList) {
      if (this.fileList.length === 0) {
        return;
      }
      // console.log(fileList[0], "filelist");
      this.$confirm(`确定移除 ${file.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteQrcodeFile();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消修改",
          // });
        });

      return false;
    },

    deleteQrcodeFile() {
      if (this.form.qrcode === "" ) {
        return;
      }
      if (this.form.qrcode != "") {
        deletFile(this.form.qrcode).then(({ data }) => {
          if (data.code === 200) {
            this.form.qrcode = "";
          }
        });
      }

     
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.current = 1;
      this.getQrcodeTableInfo( );
    },
    handleCurrentChange(val) {
      this.current = val;
      console.log(`当前页: ${val}`);
      this.getQrcodeTableInfo( );
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },

    submit() {
      let msg = "";
      if (this.action === "new") {
        msg = "确认新增微信联系人信息?";
      } else {
        msg = "确认修改微信联系人信息？";
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm(msg, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
               
              saveQrcodeInfo(qs.stringify(this.form)).then(({ data }) => {
                if (data.code === 200) {
                  this.getQrcodeTableInfo();
                  this.$message({
                    type: "success",
                    message: "处理成功!",
                  });
                  this.dialogVisible = false;
                  this.form.id = "";
                  this.form.phonenumber = "";
                  this.form.qrcode = "";
              
                }
              });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });

      // this.dialogVisible = false;
    },
    handleClose() {
      if (this.action === "new") {
        this.deleteQrcodeFile();
        this.clear();
      }
      this.dialogVisible = false;
    },
    
    clear() {
      let this_ = this;
      this.form.id = "";
      this.form.phonenumber = "";
      this.form.qrcode = "";
      if (this.action === "edit") {
      } else {
        this.deleteQrcodeFile();
      }
    },
    cancel() {
      this.handleClose();
    },
    getQrcodeTableInfo( ) {
      var this_ = this;
      const page = {
        size: this.size,
        current: this.current,
        
      };
      const params = { params: page };
      getQrCodeDataInfo(params).then((res) => {
        //   console.log(res.data, "getCarouselImages");
        if (res.data.code === 200) {
          this.$nextTick(() => {
            this.tableData = res.data.data;
            this.total = res.data.data.total;
          });
        }
      });
    },

    handlePerm() {},
    handleAvatarSuccess() {},
    handleDelete(index, row) {
      const params = { params: { id: row.id } };
      this.$confirm("确认删除该条产品信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          batchDelete(params).then(({ data }) => {
            if (data.code === 200) {
              this.deleteQrcodeFile();
              this.getQrcodeTableInfo( );
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          return;
        });
    },
    handleEdit(index, row) {
      this.action = "edit";
      this.title = "编辑产品信息";
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.form = JSON.parse(JSON.stringify(row));
      });
    },
  },
  mounted() {
   
    this.getQrcodeTableInfo( );
   
      // this.form.pId = this.$route.query.id;
      this.$set(this.form, 'pId', this.$route.query.id)
      // alert(this.form.pId)
  },
  data() {
    return {
      tableData: [],
      showflag: false,
      form: {
        id: "",
        qrcode: "",
        phonenumber: "",
     
      },
      editorOption: {},
      title: "",
      action: "",
      dialogVisible: false,
      total: 0,
      size: 15,
      current: 0,
      orders: "",

    };
  },
};
</script>
          
          <style lang='less' scoped >
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
.ql-editor{
  height: 150px;
}
.ql-blank{
  height: 150px;
}
.ql-container {
  height: 150px;
}
</style>